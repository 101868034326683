
import { Checkbox, Form, FormProps, Tag } from "antd";
import Search, { SearchProps } from "antd/es/input/Search";
import { useEffect, useState } from "react";

interface ISearchResult {
    hits: any[];
    query: string;
    processingTimeMs: number;
    limit: number;
    offset: number;
    estimatedTotalHits: number;
    facetDistribution: { [key:string]: { [option: string]: number}}
}
class SearchService
{
    public search = async (filter: string):Promise<ISearchResult|null> => {
        let results = await fetch(`./v1/scheduler/search?filter=${encodeURIComponent(filter)}`).then(x=>x.json()).then(x=>x.data).catch(err=>null);
        console.log("results", results);
        return results as unknown as ISearchResult|null;
    }
}

export const AudienceRefiner = (props: { onAudienceChange:(audienceFilter: string)=>void})=>{
    const [filterData, setFilterData] = useState<any>({})
    const [filter, setFilter] = useState("");
    const [results, setResults] = useState<ISearchResult|null>(null);
    const onSearch: SearchProps['onSearch'] = async (value, _e, info) => {
        console.log(info?.source, value);
        let searchService = new SearchService();
        await searchService.search(value);
    }

    const onValueChange: FormProps<any>['onValuesChange'] = (values) => {

        let newFilterData = {...filterData,...values};
        setFilterData(newFilterData);

        let newFilter = Object.keys(newFilterData).filter(x=>newFilterData[x] == true).map(x=>
            {
                let kv = x.split(':');

                return `( ${kv[0]} = '${kv[1]}' )`;
            }).join(' AND ');
        console.log("update: ", values, newFilter)

        props?.onAudienceChange(newFilter);

        setFilter(newFilter);
      }

    useEffect(()=>{
        let searchService = new SearchService();
        searchService.search(filter).then(res => {

            // set results
            if(res != null)
            {
                setResults(res);
            }
            
        })
        
    },[filter]);

    if(results == null || !results.facetDistribution)
    {
        return (
            <></>
            
        );
    } else {

        return (
            <div>
                <p>Estimated Target {results.estimatedTotalHits}</p>
            <Form
    //   onFinish={onFinish}
      onValuesChange={onValueChange}
      autoComplete="off"
    //   initialValues={tertiaryValues}
      labelCol={{ span: 18 }}
      wrapperCol={{ span: 24 }}
      layout="horizontal"
      style={{ width: '100%' }}
    >
                {Object.entries(results.facetDistribution).filter(facet=>!["tags.dId"].includes(facet[0])).map(facet=>{
                    return (
                        <div key={facet[0]}>
                            <h3>{facet[0].replace("tags.",'')}</h3>
                            <ul>
                            {Object.entries(facet[1]).map(entry=>{
                                return (

                                    <Form.Item key={entry[0]}  name={`${facet[0]}:${entry[0]}`} valuePropName="checked" label={`${entry[0]} (${entry[1]})`} labelAlign="left">
                                        <Checkbox />
                                    </Form.Item>
                                    
                                )
                            })}
                            </ul>

                        </div>
                    )
                })}
            </Form>
            </div>
        )
        
        
    }
   
}

const Hit = (props:{ hit:any }) => (
    <article key={props.hit.id}>
      <p>
        {Object.keys(props.hit.tags).map(x=><Tag>{x}: {props.hit.tags[x]}</Tag>)}
      </p>
    </article>
  );