import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { IDevice, PushTag, PushTagType } from "../interfaces/IDevice";
import { Messaging, getMessaging, getToken } from "firebase/messaging";
import Bowser from "bowser";
const SIXTYDAYS_MS:number = 60 * 3600 * 24 * 1000

export enum NotificationState
{
    UNSUPPORTED="unsupoorted",
    PERMISSION_DEFAULT="default",
    PERMISSION_GRANTED="granted",
    PERMISSION_DENIED="denied",
    REGISTERED="registered"
}

interface FCMToken
{
    createdAt: Date;
    expiresAt: Date;
    token: string;
}

export interface PushTags {
    tags:PushTag[]
}

export class MHQPushSDK {
    private HOST:string = ".";//http://localhost:3020";//"http://localhost:3020/api" //'./api'
    private FCM_TOKEN!: FCMToken | null;
    private FCM_APP!:FirebaseApp;
    private FCM_MESSAGING!:Messaging;
    private VAPID_PUBLIC_KEY?:string;
    private APP_VERSION:string;
    private APP_DEVICEID:string;
    private PLT:string;
    private NOTIFICATION_STATE:NotificationState = NotificationState.PERMISSION_DEFAULT
    private SERVICE_WORKER_REGISTRATION: ServiceWorkerRegistration|null;

    private static __instance:MHQPushSDK|null = null;
    public static create(options:FirebaseOptions, appVersion: string, deviceId: string, plt:string):MHQPushSDK
    {
        if(MHQPushSDK.__instance == null)
        {
            MHQPushSDK.__instance = new MHQPushSDK(options, appVersion, deviceId, plt); 
            // if initialised we restore a previous session (permission allowing)
            if([NotificationState.PERMISSION_GRANTED,NotificationState.REGISTERED].includes(MHQPushSDK.__instance.getNotificationState()))
            {
                MHQPushSDK.__instance.onload().then((state)=>{
                    if([NotificationState.PERMISSION_GRANTED,NotificationState.REGISTERED].includes(state) && MHQPushSDK.__instance)
                    {
                        MHQPushSDK.__instance.register();
                    }
                })
            } 
        }
        return MHQPushSDK.__instance;
    }
    private constructor(options:FirebaseOptions, appVersion: string, deviceId: string, plt:string)
    {
        console.log("MHQPush init")
        this.FCM_TOKEN = null;
        this.FCM_APP = initializeApp(options);
        this.FCM_MESSAGING = getMessaging(this.FCM_APP);
        this.APP_VERSION = appVersion;
        this.APP_DEVICEID = deviceId;
        this.PLT = plt;
        this.SERVICE_WORKER_REGISTRATION = null;
    }

    public getNotificationState():NotificationState
    {
        let state:NotificationState = NotificationState.UNSUPPORTED;
        if (!("Notification" in window)) {
            console.error("This browser does not support push notifications");
            state = NotificationState.UNSUPPORTED;
        }
        switch(Notification.permission)
        {
            case "default":
                state = NotificationState.PERMISSION_DEFAULT;
                break;
            case "denied":
                state = NotificationState.PERMISSION_DENIED;
                break;
            case "granted":
                state = NotificationState.PERMISSION_GRANTED;
                break;
        }

        if([NotificationState.PERMISSION_GRANTED].includes(state) && this.getCurrentSubscription() != null)
            state = NotificationState.REGISTERED;

        console.log('currentNotificationState', state);
        return state;
    }

    private getLocalStorage(key:string):string|null
    {
        let result:string|null = null;
        if(localStorage && "getItem" in localStorage)
        {
            result = localStorage.getItem(key);   
        }
        return result;
    }

    private setLocalStorage(key:string, value:string|null)
    {
        if(localStorage && "setItem" in localStorage)
        {
            if(value == null)
            {
                localStorage.removeItem(key); 
            } else {
                localStorage.setItem(key, value);   
            }
            
        }
    }

    private getFCMToken(): FCMToken|null
    {
        let storeValue = this.getLocalStorage("FCM_TOKEN");
        let token:FCMToken|null = null;

        if(storeValue != null)
        {
            try {
                let jsonStoreValue:any = JSON.parse(storeValue);
                token = {
                    createdAt: new Date(jsonStoreValue["createdAt"]),
                    expiresAt: new Date(jsonStoreValue["expiresAt"]),
                    token: jsonStoreValue["token"]
                }
            } catch(err)
            {
                // Failed to parse localstorage
                token = null;
            }
        }
        return token;
    }

    private setCurrentSubscription(subscription:IDevice)
    {
        this.setLocalStorage("MHQ_SUBSCRIPTION", JSON.stringify(subscription, null, 2));
    }

    private getCurrentSubscription(): IDevice|null
    {
        let storeValue = this.getLocalStorage("MHQ_SUBSCRIPTION");
        let device:IDevice|null = null;

        if(storeValue != null)
        {
            try {
                let jsonStoreValue:any = JSON.parse(storeValue);
                device = jsonStoreValue as IDevice
            } catch(err)
            {
                // Failed to parse localstorage
                device = null;
            }
        }
        return device;
    }

    private setFCMToken(token:FCMToken)
    {
        this.setLocalStorage("FCM_TOKEN", JSON.stringify(token, null, 2));
    }

    private async getConfig() {
        if(this.VAPID_PUBLIC_KEY == null)
        {
            this.VAPID_PUBLIC_KEY = await fetch(`${this.HOST}/v1/config`).then(x => x.json()).then(x => x.data.keypair.public as string).catch(err => undefined)
        }
    }
    
    async onload():Promise<NotificationState> {
        
        this.NOTIFICATION_STATE = this.getNotificationState();
        if(this.NOTIFICATION_STATE == NotificationState.UNSUPPORTED || this.NOTIFICATION_STATE == NotificationState.PERMISSION_DENIED)
            return this.getNotificationState();

        
        await this.getConfig();
        if(!this.VAPID_PUBLIC_KEY)
            return this.getNotificationState();

        // register service worker
        if(this.SERVICE_WORKER_REGISTRATION == null)
        {
            let serviceRegistration = await navigator.serviceWorker.register(`./firebase-messaging-sw.js?v=${this.APP_VERSION}&dId=${this.APP_VERSION}`);
            let previousVersion = this.getLocalStorage("FCM_APPVERSION");
            if(previousVersion != this.APP_VERSION)
            {
                await serviceRegistration.update();
            }
            this.setLocalStorage("FCM_APPVERSION", this.APP_VERSION);
            this.SERVICE_WORKER_REGISTRATION = serviceRegistration;
        }

        // check if we have a stored fcmToken
        let now = new Date();
        let fcmToken:FCMToken|null = this.FCM_TOKEN == null ? this.getFCMToken() : this.FCM_TOKEN;
        console.log("fcmToken-pre", fcmToken);
        if(fcmToken == null || (fcmToken.expiresAt.getTime() < (now.getTime())))
        {
            console.log("fetchingToken");
            let tokenString:string|null = await getToken(this.FCM_MESSAGING, { vapidKey: this.VAPID_PUBLIC_KEY, serviceWorkerRegistration: this.SERVICE_WORKER_REGISTRATION}).catch((err)=>{
                console.log(`An error occurred while retrieving token. `, err)
                return null;
            });
            if(tokenString != null)
            {
                let token = {
                    createdAt: new Date(),
                    expiresAt: new Date(new Date().getTime() + SIXTYDAYS_MS),
                    token: tokenString
                };
    
                if(token != null)
                {
                    this.setFCMToken(token);
                    this.FCM_TOKEN = token;
                }
            }
        }
        console.log("fcmToken", this.FCM_TOKEN);
        // we assume we are valid at this point, register it with the api
        if(fcmToken != null)
        {
            this.FCM_TOKEN = fcmToken;
            // this.register();
        }

        return this.getNotificationState();
        
    }

    public subscription():IDevice|null
    {
        return this.getCurrentSubscription();
    }

    async requestPushNotifications():Promise<NotificationState>
    {
        if(this.getNotificationState() == NotificationState.UNSUPPORTED)
            return this.getNotificationState();

        await Notification.requestPermission();
        this.NOTIFICATION_STATE = this.getNotificationState();
        if(this.NOTIFICATION_STATE == NotificationState.PERMISSION_GRANTED || this.NOTIFICATION_STATE == NotificationState.REGISTERED || this.NOTIFICATION_STATE == NotificationState.PERMISSION_DEFAULT)
        {
            let loadState = await this.onload();
            console.log("loadState", loadState);
            if([NotificationState.PERMISSION_GRANTED,NotificationState.REGISTERED].includes(loadState))
            {
                await this.register();
            }
        }
        return this.getNotificationState();
    }

    async register(pushTags?:PushTags):Promise<IDevice|null> {
        let browserTagInfo = Bowser.parse(window.navigator.userAgent);
        
        let tags:PushTag[] = [
            { name: "dId", type: PushTagType.String, value: this.APP_DEVICEID},
            { name: "plt", type: PushTagType.String, value: this.PLT }
        ];

        if(browserTagInfo.browser.name)
            tags.push({ name: "browserName", type: PushTagType.String, value: browserTagInfo.browser.name});

        if(browserTagInfo.browser.version)
            tags.push({ name: "browserVersion", type: PushTagType.String, value: browserTagInfo.browser.version});

        if(browserTagInfo.os.name)
            tags.push({ name: "OsName", type: PushTagType.String, value: browserTagInfo.os.name});

        if(browserTagInfo.os.version)
            tags.push({ name: "OsVersion", type: PushTagType.String, value: browserTagInfo.os.version});

        if(browserTagInfo.os.versionName)
            tags.push({ name: "OsVersionName", type: PushTagType.String, value: browserTagInfo.os.versionName});
    
        if(browserTagInfo.platform.type)
            tags.push({ name: "browserPlatform", type: PushTagType.String, value: browserTagInfo.platform.type});

        if(browserTagInfo.platform.model)
            tags.push({ name: "browserPlatformModel", type: PushTagType.String, value: browserTagInfo.platform.model});

        if(browserTagInfo.platform.vendor)
            tags.push({ name: "PlatformVendor", type: PushTagType.String, value: browserTagInfo.platform.vendor});

        if(browserTagInfo.engine.name)
            tags.push({ name: "browserEngineName", type: PushTagType.String, value: browserTagInfo.engine.name});

        if(browserTagInfo.engine.version)
            tags.push({ name: "browserEngineVersion", type: PushTagType.String, value: browserTagInfo.engine.version});
        
    
        if(pushTags?.tags)
        {
            let userTags = pushTags?.tags;
            tags = [
                ...tags,
                ...userTags
            ];
        }
            

        let subscription:IDevice|null = await fetch(`${this.HOST}/v1/device/register`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              deviceId: this.APP_DEVICEID,
              tags: tags,
              pushContext: {
                type: "fcmv1",
                pushChannel: this.FCM_TOKEN?.token
              }
            })
          }).then(x => x.json()).then(x => x.data as IDevice).catch(err=>null);

        if(subscription != null)
        {
            this.setCurrentSubscription(subscription);
        }
        return subscription;
    }

    // stop the service, not sure why we'd do this but you can
    async unregister():Promise<boolean> {
        let serviceRegistration = await window.navigator.serviceWorker.ready;
        let result = await serviceRegistration.unregister();
        if(result)
        {
            this.SERVICE_WORKER_REGISTRATION = null;
        }
        return result;
    }
}

