import { Flex } from "antd";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

export interface INotificationPreviewProps {
    type: "safari-mac"|"chrome-mac"|"ios",
    title:string,
    message:string,
    imageUrl?:string,
    deepLinkUrl:string
}

const macTemplate = (props:{notification:INotificationPreviewProps, style: {backgroundColor:string, foregroundColor:string}, iconUrl:string})=>{
    return (
        <a href={props.notification.deepLinkUrl}>
            <div style={{ 
                border: `1px solid ${props.style.backgroundColor}`, borderRadius: '10pt',
                padding: '2pt',
                backgroundColor: props.style.backgroundColor,
                width: "220pt",
                height: "40pt",
                paddingTop: '7pt',
                paddingBottom: '7pt'
                }}>
            <Flex vertical={false} style={{width: "100%", height: "100%", paddingLeft: '5pt',paddingRight: '5pt'}} justify="space-between" align="center" gap={5}>
                <img style={{margin: '2pt', padding: '2pt', borderRadius: '7pt', width: '30pt', background: 'white'}} src={props.iconUrl}></img>
                <Flex vertical justify="space-between" align="start" style={{width: '100%'}} gap={2}>
                    <div style={{fontSize: '10pt', color: props.style.foregroundColor}}>{props.notification.title}</div>
                    <div style={{fontSize: '10pt', color: props.style.foregroundColor, textOverflow: "ellipsis", overflow: 'hidden',
    whiteSpace: 'nowrap', maxWidth: '138pt'}}><b>from {new URL(window.location.href).host}</b></div>
                    <div style={{fontSize: '10pt', color: props.style.foregroundColor}}>{props.notification.message}</div>
                </Flex>
                {props.notification.type == "safari-mac" || props.notification.imageUrl == "" ? (<></>) : (<Flex vertical justify="end" style={{height: "100%"}}><img style={{borderRadius: '7pt', width: '28pt', marginRight: "8pt", objectFit: 'cover', aspectRatio: 1}} src={props.notification.imageUrl}></img></Flex>)}
            </Flex>
            </div>
        </a>
    )
}

const googleTemplate = (props:{notification:INotificationPreviewProps, style: {backgroundColor:string, foregroundColor:string}, iconUrl:string})=>{
    // const [isExpanded, setIsExpanded] = useState(false);
    
    return (
        <a href={props.notification.deepLinkUrl}>
            <div style={{ 
                border: `1px solid ${props.style.backgroundColor}`, borderRadius: '10pt',
                padding: '2pt',
                backgroundColor: props.style.backgroundColor,
                width: "220pt",
                height: "40pt",
                paddingTop: '7pt',
                paddingBottom: '7pt'
                }}>
            <Flex vertical={false} style={{width: "100%", height: "100%", paddingLeft: '5pt',paddingRight: '5pt'}} justify="space-between" align="center" gap={5}>
                <img style={{margin: '2pt', padding: '2pt', borderRadius: '7pt', width: '30pt', background: 'white'}} src={props.iconUrl}></img>
                <Flex vertical justify="space-between" align="start" style={{width: '100%'}} gap={2}>
                    <div style={{fontSize: '10pt', color: props.style.foregroundColor}}>{props.notification.title}</div>
                    <div style={{fontSize: '10pt', color: props.style.foregroundColor, textOverflow: "ellipsis", overflow: 'hidden',
    whiteSpace: 'nowrap', maxWidth: '138pt'}}><b>from {new URL(window.location.href).host}</b></div>
                    <div style={{fontSize: '10pt', color: props.style.foregroundColor}}>{props.notification.message}</div>
                </Flex>
                {props.notification.type == "safari-mac" || props.notification.imageUrl == "" ? (<></>) : (<Flex vertical justify="end" style={{height: "100%"}}><img style={{borderRadius: '7pt', width: '28pt', marginRight: "8pt", objectFit: 'cover', aspectRatio: 1}} src={props.notification.imageUrl}></img></Flex>)}
            </Flex>
            </div>
        </a>
    )
}

const iosTemplate = (props:{notification:INotificationPreviewProps, style: {backgroundColor:string, foregroundColor:string}, iconUrl:string})=>{
    return (
        <a href={props.notification.deepLinkUrl}>
            <div style={{ 
                border: `1px solid ${props.style.backgroundColor}`, borderRadius: '10pt',
                padding: '2pt',
                backgroundColor: props.style.backgroundColor,
                width: "220pt",
                height: "40pt",
                paddingTop: '7pt',
                paddingBottom: '7pt'
                }}>
            <Flex vertical={false} style={{width: "100%", height: "100%", paddingLeft: '5pt',paddingRight: '5pt'}} justify="space-between" align="center" gap={5}>
                <img style={{margin: '2pt', padding: '2pt', borderRadius: '7pt', width: '30pt', background: 'white'}} src={props.iconUrl}></img>
                <Flex vertical justify="space-between" align="start" style={{width: '100%'}} gap={2}>
                    <div style={{fontSize: '10pt', color: props.style.foregroundColor}}>{props.notification.title}</div>
                    <div style={{fontSize: '10pt', color: props.style.foregroundColor}}>{props.notification.message}</div>
                </Flex>
                {props.notification.imageUrl == "" ? (<></>) : (<img style={{ borderRadius: '7pt', width: '30pt', marginRight: "8pt", objectFit: 'cover', aspectRatio: 1}} src={props.notification.imageUrl}></img>)}
            </Flex>
            </div>
        </a>
    )
}


export const NotificationPreview = (props:INotificationPreviewProps)=> {
    const [backgroundColor, setBackgroundColor] = useState<string>('black');
    const [foregroundColor, setForegroundColor] = useState<string>('white');
    const systemPrefersDark = useMediaQuery(
        {
          query: "(prefers-color-scheme: dark)",
        },
        undefined,
        (isSystemDark) => {
            setBackgroundColor(isSystemDark ? "black" : "lightgray")
            setForegroundColor(isSystemDark ? "white" : "black")
        }
      );
    

   
    let notification = (<></>)
    let iconUrl="";
    switch(props.type)
    {
        default:
        case "safari-mac":
            iconUrl="https://upload.wikimedia.org/wikipedia/commons/5/52/Safari_browser_logo.svg";
            notification=macTemplate({ notification: props, iconUrl: iconUrl, style: { backgroundColor: backgroundColor, foregroundColor: foregroundColor}})
            break;
        case "chrome-mac":
            iconUrl="https://www.google.com/chrome/static/images/chrome-logo-m100.svg";
            notification=macTemplate({ notification: props, iconUrl: iconUrl, style: { backgroundColor: backgroundColor, foregroundColor: foregroundColor}})
            break;
        case "ios":
            iconUrl="https://watch.tonton.com.my/static/media/favicons/favicon-96x96.png"
            notification=iosTemplate({ notification: props, iconUrl: iconUrl, style: { backgroundColor: backgroundColor, foregroundColor: foregroundColor}})
            break;    
    }

    return (
        <div>
            {notification}
            <Flex justify="end"><small>{props.type.split('-').join(" ")}</small></Flex>
        </div>
    )

}