import { useEffect, useState } from "react"
import { Button, Flex } from "antd";
import { MHQPushSDK, NotificationState } from "../../services/MHQPushSDK";

export const RecieverApp = ()=>{
    let firebaseConfig = {
        apiKey: "AIzaSyASjC2E2WuoZvEaSa6TJu40dORkHCOU8Kg",
        authDomain: "mhq-demo-app.firebaseapp.com",
        projectId: "mhq-demo-app",
        storageBucket: "mhq-demo-app.appspot.com",
        messagingSenderId: "222086286395",
        appId: "1:222086286395:web:09103ebee2b5daf42c601e",
        measurementId: "G-YVWTM58T8R"
      };
    const APP_VERSION: string = `0.1.1`
    const DEVICE_ID: string = "fake-testDeviceId-" + navigator.userAgent.replace(/ /, '-')
    const PLT: string = "web";

    const [sdk, setSDK] = useState<MHQPushSDK>(MHQPushSDK.create(firebaseConfig, APP_VERSION, DEVICE_ID, PLT))
    const [pushState, setPushState] = useState<NotificationState>(sdk.getNotificationState())

    return (
        <Flex vertical={true} align="center" gap={5}>
            <Button onClick={()=>sdk?.requestPushNotifications().then(x=>setPushState(x))}>Register for Push Notifications</Button>
            <Button onClick={()=>sdk?.unregister().then(x=>setPushState(sdk.getNotificationState()))}>Unregister for Push Notifications</Button>
            {pushState.toString()}
        </Flex>
    )
}