import { Button, Card, DatePicker, Flex, Form, FormProps, Input, Select, Space, Typography, Row, Col } from "antd"
import { APIService } from "../services/API.service";
import { FCMPushContext } from "../interfaces/FCMPushContext";
import { PushContextType } from "../PushContextType";
import { NotificationPreview } from "./NotificationPreview/NotificationPreview";
import { useEffect, useState } from "react";
import { CloseOutlined } from '@ant-design/icons';
import { CampaignMetrics } from "../interfaces/campaignmetrics.interface";
import { AudienceRefiner } from "./AudienceRefiner";

interface NotificationScheduleForm {
  title: string;
  message: string;
  deepLinkUrl: string;
  imageUrl?: string;
  tags: string[];
  audienceFilter: string;
  sendAt: string;
}
export const NotificationScheduler = () => {
  const [campaignId, setCampaignId] = useState<string|null>(null);
  const[campaignMetrics, setCampaignMetrics] = useState<CampaignMetrics|null>(null);
  const [ form ] = Form.useForm();

  const onFinish: FormProps<NotificationScheduleForm>['onFinish'] = async (values) => {

    if (values.title && values.title.length > 0
      && values.message && values.message.length > 0
      && values.sendAt) {
      
      if (values.sendAt) {
        values.sendAt = new Date(values.sendAt).toISOString();
      }
      console.log('Success:', values);

      let api = new APIService()
      // let allRegisteredTargets = await api.getRegistrations();
      // if (allRegisteredTargets.length > 0) {
        let campaignId = await api.sendNotification([], {
          title: values.title,
          message: values.message,
          imageUrl: (values.imageUrl && values.imageUrl.length > 5) ? values.imageUrl : null,
          deepLinkUrl: values.deepLinkUrl,
          audienceFilter: values.audienceFilter ?? "",
          sendAt: values.sendAt
        })
        if(campaignId != null)
          {
            setCampaignId(campaignId)
          }
      

      

      // api.sendNotification()
    } else {
      console.log('Failure:', values);
    }


  };

  const [tertiaryValues, setTertiaryValues] = useState<NotificationScheduleForm>({
    title: "Title",
    message: "Message",
    deepLinkUrl: "https://watch.tonton.com.my",
    imageUrl: "https://headend-api.tonton.com.my/v210/imageHelper.php?id=6508308:380:STILL:LANDSCAPE&w=410&appID=TONTON",
    tags: [],
    audienceFilter: "",
    sendAt: ""
  });
  const onValueChange: FormProps<NotificationScheduleForm>['onValuesChange'] = (values) => {

    setTertiaryValues({ ...tertiaryValues, ...values });
    console.log("update: ", tertiaryValues)
  }

  useEffect(()=>{
    console.log("updating campaign stats 1", campaignId)
   updateCampaignStats();
    
  },[campaignId]);

  const updateCampaignStats = () => {
    console.log("updating campaign stats 2", campaignId)
    if(campaignId != null)
      {
        let api = new APIService();
        api.getCampaignStats(campaignId).then(stats=>{
          console.log("updating campaign stats3", stats)
          if(stats != null)
            {
              setCampaignMetrics(stats);
            }
        })
      }
  }

  return (<Flex vertical={false} gap={10} style={{ padding: '10pt' }}>
    <Form
     form={form}
      onFinish={onFinish}
      onValuesChange={onValueChange}
      autoComplete="off"
      initialValues={tertiaryValues}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      style={{ width: '60%', maxWidth: '1024pt' }}
    >
      <Row>
        <Col span={8}></Col>
        <Col span={16}><Typography.Title level={4}>Content</Typography.Title></Col>
      </Row>
      <Form.Item label="Title" name="title">
        <Input placeholder={tertiaryValues.title} />
      </Form.Item>
      <Form.Item label="Message" name="message">
        <Input placeholder={tertiaryValues.message} />
      </Form.Item>
      <Form.Item label="Deeplink" name="deepLinkUrl">
        <Input placeholder={tertiaryValues.deepLinkUrl} />
      </Form.Item>
      <Form.Item label="ImageUrl" name="imageUrl">
        <Input placeholder={tertiaryValues.imageUrl} />
      </Form.Item>
      
      <Form.Item label="Audience Filter" name="audienceFilter">
        <Input />
      </Form.Item>
      <Row>
        <Col span={8}></Col>
        <Col span={16}><Typography.Title level={4}>Audience</Typography.Title></Col>
        <AudienceRefiner onAudienceChange={(filter)=> form.setFieldValue("audienceFilter", filter) } />
      </Row>
      
      <Form.Item label="Send at" name="sendAt">
        {/* <Flex vertical={false} align="start"> */}
        <DatePicker showTime showNow />
        {/* </Flex> */}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Schedule
        </Button>
      </Form.Item>
    </Form>
    <Flex vertical gap={10} style={{ marginTop: '45pt' }}>
      <NotificationPreview type="safari-mac"
        title={tertiaryValues.title}
        message={tertiaryValues.message}
        imageUrl={tertiaryValues.imageUrl}
        deepLinkUrl={tertiaryValues.deepLinkUrl} />

      <NotificationPreview type="chrome-mac"
        title={tertiaryValues.title}
        message={tertiaryValues.message}
        imageUrl={tertiaryValues.imageUrl}
        deepLinkUrl={tertiaryValues.deepLinkUrl} />

      <NotificationPreview type="ios"
        title={tertiaryValues.title}
        message={tertiaryValues.message}
        imageUrl={tertiaryValues.imageUrl}
        deepLinkUrl={tertiaryValues.deepLinkUrl} />
    </Flex>

    <Flex vertical gap={10} style={{ marginLeft: '20pt',marginTop: '45pt' }}>
        {campaignMetrics != null ? (
          <div>
          {Object.entries(campaignMetrics).map(entry => {
            
            switch(entry[0])
            {
              case "errors":
                return (<div>
                  <Typography.Title level={3}>Errors</Typography.Title>
                  <div>
                  {Object.entries<number>(entry[1]).map(error=>(<Typography.Paragraph key={error[0]}>{error[0]}: {error[1]}</Typography.Paragraph>))}
                  </div>
                  </div>)
                break;
              default:
                return (<Typography.Paragraph key={entry[0]}>{entry[0]}: {entry[1]}</Typography.Paragraph>)
            }
            
          })}
          <Button onClick={()=>updateCampaignStats()}>Refresh Stats</Button>
          </div>
          ):(<div></div>)}
    </Flex>
  </Flex>)
}