import { PushContextType } from "../PushContextType";
import { ApplePushContext } from "../interfaces/ApplePushContext";
import { BrowserPushContext } from "../interfaces/BrowserPushContext";
import { FCMPushContext } from "../interfaces/FCMPushContext";
import { IDevice } from "../interfaces/IDevice";
import { TargetingPushContext } from "../interfaces/TargetingPushContext";
import { CampaignMetrics } from "../interfaces/campaignmetrics.interface";

export interface IBaseNotificationMessage {
    title: string;
    message: string;
    imageUrl: string|null
    deepLinkUrl: string;
    audienceFilter: string;
    sendAt: string;
}
export class APIService
{
    host:string = ".";//http://localhost:3020";//"http://localhost:3020/api" //'./api'
    getRegistrations = async ():Promise<IDevice[]> => {
      return await fetch(`${this.host}/v1/device`).then(x=>x.json()).then(x=>x.data as IDevice[]).catch(err => []);
    }

    sendNotification= async (pushContext:(ApplePushContext|FCMPushContext|BrowserPushContext|TargetingPushContext)[], notification:IBaseNotificationMessage):Promise<string|null>  => {
      let campaignId:string|null = null;
        try {
    
          console.log("send", pushContext, notification);
          let fcmPushContext = pushContext.filter(x=>x.type == PushContextType.FCM);
          if((pushContext != null && fcmPushContext.length > 0) || ((notification.audienceFilter ?? null) !== null))
          {
              // let failures=500000;
              // let failureFCM= {...fcmPushContext[0]} as FCMPushContext;
              // failureFCM.pushChannel = `FAIL_${failureFCM.pushChannel}`;
              // for(let i=0;i < failures;i++)
              // {
              //   fcmPushContext.push({...failureFCM});
              // }

              campaignId = await fetch(`${this.host}/v1/notification/schedule`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    title: notification.title,
                    message: notification.message,
                    deepLinkUrl: notification.deepLinkUrl,
                    audienceFilter: notification.audienceFilter,
                    imageUrl: notification.imageUrl,
                    sendAt: notification.sendAt,
                    // targets: []
                }),
              }).then(x=>x.json()).then(x=>x.data.campaignId);
          } 
        } catch (error) {
          console.error('Error sending notification:', error);
        }
        return campaignId;
      };

    getCampaignStats = async (campaignId:string):Promise<CampaignMetrics|null> => {
      return await fetch(`${this.host}/v1/campaign/${campaignId}/report`).then(x=>x.json()).then(x=>x.data as CampaignMetrics).catch(err=>null);
    }
     
}