import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { APIService, IBaseNotificationMessage } from "./services/API.service"
import { NotificationView } from './components/Notification';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";
import { NotificationScheduler } from './components/NotificationScheduler';
import { Button, Flex, Space, Table, TableProps, Tag, Typography } from 'antd';
import { PushContextType } from './PushContextType';
import { ApplePushContext } from './interfaces/ApplePushContext';
import { FCMPushContext } from './interfaces/FCMPushContext';
import { BrowserPushContext } from './interfaces/BrowserPushContext';
import { IDevice } from './interfaces/IDevice';
import { RecieverApp } from './components/PushReciever/RecieverApp';

const APP_VERSION: string = `0.1.0-${Math.floor(new Date().getTime()/1000)}`
const DEVICE_ID: string = "fake-testDeviceId-" + window.navigator.userAgent.replace(/ /,'-');
const getDeviceId = ():string =>{
  let deviceId = null;
  if(localStorage && "getItem" in localStorage)
  {
    deviceId = localStorage.getItem("deviceId"); 
  }
  if(deviceId == null)
  {
    deviceId = DEVICE_ID;
    if(localStorage && "setItem" in localStorage)
    {
      localStorage.setItem("deviceId",deviceId)
    }
  }

  return deviceId;
}

const firebaseConfig = {
  apiKey: "AIzaSyASjC2E2WuoZvEaSa6TJu40dORkHCOU8Kg",
  authDomain: "mhq-demo-app.firebaseapp.com",
  projectId: "mhq-demo-app",
  storageBucket: "mhq-demo-app.appspot.com",
  messagingSenderId: "222086286395",
  appId: "1:222086286395:web:09103ebee2b5daf42c601e",
  measurementId: "G-YVWTM58T8R"
};
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(firebaseApp);

const API: APIService = new APIService();


enum SubscriptionSetupStatus {
  UNSUBSCRIBED = "unsubscribed",
  SUBSCRIBING = "subscribing",
  DONE = "done"
}
const SIXTYDAYS_MS:number = 60 * 3600 * 24 * 1000
function App() {
  const [subscription, setSubscription] = useState<null | IDevice>(null);
  const [registrations, setRegistrations] = useState<IDevice[]>([]);

  const [vapidKey, setVapidKey] = useState<string | null>(null);

  useEffect(() => {
    new APIService().getRegistrations().then(x => setRegistrations(x));
  }, [subscription]);


//   const subscribe = async () => {
//     try {
//       // Let's check if the browser supports notifications
//       if (!("Notification" in window)) {
//         console.error("This browser does not support push notifications");
//         return;
//       }
//       // Let's check whether notification permissions have already been granted
//       else if (Notification.permission === "granted") {
//         console.log("Permission to receive notifications has been granted");
//       }
  
//       let permission = await Notification.requestPermission();
//       console.log("notification_permission", permission);

//       if (vapidKey == null) {
//         let VAPID_PUBLIC_KEY = await fetch("./api/v1/config").then(x => x.json()).then(x => x.data.keypair.public as string).catch(err => null);
//         setVapidKey(VAPID_PUBLIC_KEY);
//       }

//       if (vapidKey == null)
//         return;

//       // Get registration token. Initially this makes a network call, once retrieved
//       // subsequent calls to getToken will return from cache.
//       console.log("getToken")
      
//       navigator.serviceWorker.register(`./firebase-messaging-sw.js?v=${APP_VERSION}&dId=${getDeviceId()}`)
// .then(async (registration) => {
//   await registration.update();
//   getToken(messaging, { vapidKey: vapidKey, serviceWorkerRegistration: registration }).then(async (currentToken) => {
//     if (currentToken) {
//       if(localStorage && "setItem" in localStorage)
//       {
//         console.log("can set item");
//         localStorage.setItem("FCM_TOKEN", JSON.stringify({ token: currentToken, createdAt: new Date(), expiresAt: new Date(new Date().getTime() + SIXTYDAYS_MS)}))
//       }
//       console.log("fcm", currentToken);
//       await fetch("./api/v1/device/register", {
//         method: "POST",
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           deviceId: getDeviceId(),
//           tags: [
//             `did:${getDeviceId()}`,
//             `plt:web`
//           ],
//           pushContext: {
//             type: "fcmv1",
//             pushChannel: currentToken
//           }
//         })
//       }).then(x => x.json()).then(x => x.data as IDevice).then(x => setSubscription(x))

//     } else {
//       // Show permission request UI
//       console.log('No registration token available. Request permission to generate one.');
//       let permission = await Notification.requestPermission();
//       console.log("notification_permission", permission);
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // ...
//   });
// });


//     } catch (err) {
//       console.log("subscribeErr", err);
//     }
//   }
  const sendNotification = async (pushContext: ApplePushContext | FCMPushContext | BrowserPushContext | null, message: IBaseNotificationMessage) => {
    try {

      console.log("send", pushContext);

      if (pushContext != null) {
        await API.sendNotification([pushContext], message);
      }


    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const getFCMTOKEN = ():string|null=> {
    try {
      let localValue = localStorage.getItem("FCM_TOKEN");
      if(localValue)
      {
        let localObj = JSON.parse(localValue);
        return localObj?.token ?? null;
      }
    } catch (err) {

    }
    return null;
  }

  enum PushType {
    FCMv1=1
  }

  const columns: TableProps<IDevice>['columns'] = [
    {
      title: 'FCMID',
      key: '.',
      // render: (text) => <p>{text}</p>
      render: (text) => <Typography.Text copyable>{text.pushChannel}</Typography.Text>,
    },
    {
      title: 'Tags',
      key: '.',
      render: (_, { tags }) => (
        <>
          {Object.entries(tags).map(t=>`${t[0]}:${t[1]}`).map((tag) => {
            let color = tag.length > 5 ? 'geekblue' : 'green';
            if (tag === 'loser') {
              color = 'volcano';
            }
            if(tag.toUpperCase().startsWith("DID"))
            {
              tag = "DID:" + tag.substring(tag.length - 1 - 45 , tag.length -1)
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'lastSeen',
      key: '.',
      render: (text) => <Typography.Text>{new Date(typeof text.lastSeen == "number" ? new Date(text.lastSeen * 1000) : new Date(text.lastSeen)).toDateString()}</Typography.Text>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => sendNotification(record.pushContext, { title: "Test Title", message: "Test Message", deepLinkUrl: `${new URL(window.location.href).origin}\#Test-Title`, imageUrl: "https://ca.slack-edge.com/T0R5ZDJVC-U0VKLFVRN-2a95f3d73636-512", audienceFilter: `( tags.dId = ${record.deviceId})`, sendAt: new Date().toISOString() })}>Send Test Push</a>
        </Space>
      ),
    },
  ];

  return (
    <div className="App">
      <h1>Push Notification Service</h1>
      <div>
        <h2>Application</h2>
        <RecieverApp />
        {/* <Flex vertical gap={5} align="center">
        <Button onClick={subscribe}>Subscribe to Push Notifications</Button>
        <Button onClick={() => sendNotification(subscription?.pushContext ?? null, { title: "Test Title", message: "Test Message", deepLinkUrl: `${new URL(window.location.href).origin}\#Test-Title`, imageUrl: "https://ca.slack-edge.com/T0R5ZDJVC-U0VKLFVRN-2a95f3d73636-512", sendAt: new Date().toISOString() })}>Send Notification</Button>
        {subscription && (
          <NotificationView title="Subscription Successful" body="You are now subscribed to push notifications!" />
        )}
        </Flex> */}
      </div>

      <hr />
      <div>
        <h2>Sender</h2>
        <NotificationScheduler />
      </div>
      <hr />
      <div>
        <h2>Registered Devices</h2>
        <Table rowKey={'deviceId'} columns={columns} dataSource={registrations} />
      </div>
      <hr />
      <div>
        <h2>Monitoring</h2>
        <a href="/admin/queues"><Button>Monitor Queue</Button></a>
      </div>
    </div>
  );
}

export default App;
