import { ApplePushContext } from './ApplePushContext';
import { FCMPushContext } from './FCMPushContext';
import { BrowserPushContext } from './BrowserPushContext';


export interface IDevice {
  id?: string;
  createdAt?: Date;
  lastSeen?: Date;
  deviceId: string;
  pushContext: ApplePushContext | FCMPushContext | BrowserPushContext;
  tags: { [key:string]: number|string|boolean }
}

export interface PushTag {
  name:string, 
  type:PushTagType, 
  value: number|string
}

export enum PushTagType
{
    Number="number",
    String="string"
}
